import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const BlurbTemplate = ({ data }) => {
  const { wpOurTeam } = data; // data.markdownRemark holds your post data
  const { teamMemberFields, title, slug } = wpOurTeam;
  const { siteUrl } = data.site.siteMetadata;

  return (
    <Layout>
      <GatsbySeo
        title={`${title} | Holding Space Team`}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/our-team/${slug}`,
          title: `${title}`,
          //   images: [
          //     {
          //       url: `${siteUrl}${frontmatter.path}`,
          //       width: 1920,
          //       height: 1080,
          //       alt: "Example",
          //     },
          //   ],
        }}
      />
      <Container className="py-5">
        <Row>
          <Col>
            <h1 className="text-primary">{title}</h1>
          </Col>
        </Row>
        {teamMemberFields?.description && (
          <Row className="pt-4">
            <Col>
              <div className="page-text">
                <SafeHtmlParser htmlContent={teamMemberFields?.description} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default BlurbTemplate;

export const blogData = graphql`
  query ($id: String!) {
    wpOurTeam(id: { eq: $id }) {
      title
      slug
      teamMemberFields {
        description
        firstName
        lastName
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
